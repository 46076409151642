import { useCallback } from 'react'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'

export const useTrackingDownloadIOSClicked = (view: string) => {
  const trackDownloadIOSClicked = useCallback(() => {
    trackAmplitudeCustomEvent('download_ios_clicked', { view })
  }, [view])

  return trackDownloadIOSClicked
}
