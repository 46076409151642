import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'

export const useDeeplinkToPage = ({ forIos = true } = {}) => {
  const link = useMemo(() => {
    // show app store link on desktop
    if (forIos) return 'https://apps.apple.com/us/app/cohart-discover-art-together/id1554034352'

    return 'https://play.google.com/store/apps/details?id=com.cohartinc.cohart'
  }, [forIos])

  return link
}

const useShowingAppSuggestion = () => {
  const link = useDeeplinkToPage()
  const ctaButton = useMemo(() => {
    if (!isMobile) return null
    return (
      <a
        className="inline-flex items-center rounded-full border border-black bg-[#A8FF8F] px-2 py-1 text-base uppercase text-black md:hidden"
        href={link}
        rel="noreferrer"
        target="_blank"
      >
        Use app
      </a>
    )
  }, [link])

  return ctaButton
}

export default useShowingAppSuggestion
