import classNames from 'classnames'

const DownloadAppstoreIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames('h-[99px] w-[295px]', className)}
    viewBox="0 0 295 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3143_680657)">
      <path
        d="M271.435 0.432568L24.111 0.432568C23.2095 0.432568 22.3188 0.432568 21.4197 0.437451C20.667 0.442335 19.9203 0.45652 19.1604 0.468459C17.5096 0.487752 15.8626 0.631985 14.2339 0.899906C12.6074 1.17366 11.0319 1.68976 9.56059 2.43076C8.0911 3.17803 6.74839 4.14902 5.58116 5.30852C4.40781 6.46476 3.42972 7.80122 2.68453 9.2665C1.93727 10.7289 1.41912 12.2959 1.14797 13.9136C0.874247 15.5292 0.726953 17.1634 0.707412 18.8016C0.684597 19.5501 0.682188 20.3012 0.670166 21.0499L0.670166 77.4846C0.682188 78.2427 0.684597 78.9771 0.707412 79.7355C0.726959 81.3736 0.874253 83.0077 1.14797 84.6232C1.41837 86.2418 1.93655 87.8098 2.68453 89.2727C3.42939 90.7332 4.40761 92.0643 5.58116 93.2141C6.74396 94.3788 8.08754 95.3504 9.56059 96.092C11.0319 96.8349 12.6072 97.3541 14.2339 97.6321C15.8629 97.8979 17.5097 98.0422 19.1604 98.0638C19.9203 98.0804 20.667 98.0899 21.4197 98.0899C22.3188 98.0948 23.2096 98.0948 24.111 98.0948L271.435 98.0948C272.318 98.0948 273.216 98.0948 274.1 98.0899C274.849 98.0899 275.617 98.0804 276.366 98.0638C278.014 98.0433 279.658 97.899 281.283 97.6321C282.915 97.3522 284.496 96.8331 285.975 96.092C287.446 95.35 288.789 94.3784 289.95 93.2141C291.121 92.0598 292.101 90.7297 292.855 89.2727C293.598 87.8087 294.111 86.2409 294.377 84.6232C294.652 83.0075 294.804 81.3737 294.834 79.7355C294.843 78.9771 294.843 78.2427 294.843 77.4846C294.862 76.5976 294.862 75.7155 294.862 74.8141V23.7156C294.862 22.8214 294.862 21.9345 294.843 21.0499C294.843 20.3012 294.843 19.5501 294.834 18.8015C294.804 17.1631 294.652 15.5293 294.377 13.9135C294.11 12.2968 293.597 10.7298 292.855 9.26641C291.338 6.3294 288.931 3.9387 285.975 2.43054C284.496 1.69135 282.915 1.17539 281.283 0.899687C279.658 0.630585 278.014 0.486302 276.366 0.468117C275.617 0.456203 274.849 0.441895 274.1 0.437134C273.216 0.432251 272.318 0.432251 271.435 0.432251V0.432568Z"
        fill="#A6A6A6"
      />
      <path
        d="M21.4316 95.9581C20.6826 95.9581 19.9516 95.9486 19.2084 95.932C17.6689 95.912 16.1329 95.7789 14.6132 95.5337C13.1961 95.2914 11.8233 94.8405 10.5401 94.196C9.26868 93.5569 8.10904 92.7188 7.1056 91.7139C6.08764 90.7209 5.24014 89.5691 4.59669 88.3043C3.94615 87.0312 3.49594 85.6667 3.26174 84.2582C3.00882 82.7447 2.87198 81.2143 2.8524 79.6802C2.83681 79.1653 2.81641 77.4509 2.81641 77.4509L2.81641 21.0496C2.81641 21.0496 2.83814 19.3614 2.85252 18.8655C2.87127 17.3339 3.00731 15.8059 3.25947 14.2947C3.49411 12.8823 3.94467 11.5138 4.59556 10.2366C5.23665 8.97266 6.07944 7.82005 7.09127 6.82345C8.10196 5.81739 9.26531 4.97501 10.5389 4.32704C11.8191 3.68462 13.1893 3.23694 14.6036 2.99898C16.1283 2.75132 17.6696 2.61743 19.2145 2.59841L21.4328 2.5686L274.088 2.5686L276.333 2.59961C277.863 2.61768 279.391 2.75038 280.901 2.99658C282.33 3.23753 283.714 3.68834 285.009 4.33419C287.561 5.64005 289.637 7.70603 290.947 10.2426C291.587 11.511 292.031 12.8683 292.262 14.2685C292.518 15.792 292.66 17.332 292.69 18.8762C292.697 19.5677 292.697 20.3104 292.697 21.0496C292.716 21.9651 292.716 22.8366 292.716 23.7152V74.8137C292.716 75.7007 292.716 76.5663 292.697 77.4389C292.697 78.2329 292.697 78.9602 292.687 79.7088C292.658 81.2254 292.518 82.738 292.267 84.2342C292.038 85.6529 291.59 87.0281 290.939 88.3116C290.291 89.5627 289.449 90.7045 288.443 91.6949C287.438 92.7052 286.277 93.5481 285.002 94.1914C283.711 94.8408 282.328 95.2933 280.901 95.5337C279.382 95.7802 277.846 95.9134 276.306 95.932C275.586 95.9486 274.832 95.9581 274.1 95.9581L271.435 95.963L21.4316 95.9581Z"
        fill="black"
      />
      <path
        d="M61.564 49.9975C61.5905 47.9596 62.1355 45.9615 63.1485 44.189C64.1614 42.4166 65.6094 40.9274 67.3576 39.86C66.247 38.2848 64.7819 36.9885 63.0786 36.074C61.3753 35.1595 59.4807 34.652 57.5454 34.5919C53.417 34.1615 49.4147 37.0452 47.311 37.0452C45.1665 37.0452 41.9274 34.6346 38.4395 34.7059C36.1834 34.7783 33.9847 35.4298 32.0575 36.597C30.1304 37.7642 28.5405 39.4073 27.4429 41.3661C22.6882 49.5415 26.2348 61.5565 30.7894 68.1648C33.0682 71.4007 35.7314 75.0153 39.2162 74.8872C42.6262 74.7467 43.8998 72.7276 48.0161 72.7276C52.0941 72.7276 53.289 74.8872 56.8445 74.8057C60.5037 74.7467 62.8091 71.5554 65.0079 68.2888C66.6452 65.9831 67.9051 63.4348 68.7409 60.7383C66.6151 59.8453 64.8009 58.3507 63.5246 56.4406C62.2484 54.5306 61.5665 52.2898 61.564 49.9975V49.9975Z"
        fill="white"
      />
      <path
        d="M54.8483 30.2458C56.8434 27.8672 57.8264 24.81 57.5883 21.7233C54.5402 22.0412 51.7246 23.488 49.7026 25.7753C48.7139 26.8927 47.9567 28.1927 47.4742 29.6009C46.9918 31.0091 46.7935 32.498 46.8908 33.9823C48.4154 33.9979 49.9237 33.6697 51.302 33.0225C52.6804 32.3753 53.8929 31.4259 54.8483 30.2458Z"
        fill="white"
      />
      <path
        d="M104.67 66.6952H93.0327L90.2381 74.8902H85.3091L96.3315 44.5709H101.452L112.475 74.8902H107.462L104.67 66.6952ZM94.2379 62.9136H103.462L98.9149 49.6138H98.7876L94.2379 62.9136Z"
        fill="white"
      />
      <path
        d="M136.279 63.8388C136.279 70.7081 132.577 75.1215 126.99 75.1215C125.575 75.195 124.168 74.8712 122.929 74.1873C121.691 73.5033 120.671 72.4867 119.987 71.254H119.881V82.2029H115.312V52.785H119.735V56.4617H119.819C120.534 55.2349 121.571 54.2236 122.819 53.5355C124.067 52.8473 125.48 52.5081 126.906 52.5538C132.555 52.5538 136.279 56.9886 136.279 63.8388ZM131.583 63.8388C131.583 59.3634 129.254 56.4212 125.701 56.4212C122.21 56.4212 119.862 59.4254 119.862 63.8388C119.862 68.2928 122.21 71.2756 125.701 71.2756C129.254 71.2756 131.583 68.3547 131.583 63.8388Z"
        fill="white"
      />
      <path
        d="M160.778 63.8388C160.778 70.7081 157.076 75.1214 151.489 75.1214C150.074 75.195 148.666 74.8712 147.428 74.1872C146.189 73.5033 145.169 72.4867 144.486 71.254H144.38V82.2029H139.811V52.785H144.233V56.4617H144.317C145.033 55.2349 146.07 54.2236 147.318 53.5355C148.566 52.8473 149.978 52.5081 151.405 52.5538C157.054 52.5538 160.778 56.9886 160.778 63.8388ZM156.082 63.8388C156.082 59.3634 153.753 56.4211 150.2 56.4211C146.709 56.4211 144.361 59.4254 144.361 63.8388C144.361 68.2927 146.709 71.2755 150.2 71.2755C153.753 71.2755 156.082 68.3547 156.082 63.8388H156.082Z"
        fill="white"
      />
      <path
        d="M176.969 66.4425C177.308 69.4492 180.249 71.4233 184.268 71.4233C188.119 71.4233 190.889 69.4491 190.889 66.7381C190.889 64.3847 189.218 62.9756 185.262 62.0099L181.305 61.0633C175.699 59.7186 173.097 57.1149 173.097 52.8898C173.097 47.6586 177.687 44.0654 184.205 44.0654C190.656 44.0654 195.079 47.6586 195.228 52.8898H190.616C190.34 49.8641 187.821 48.0377 184.141 48.0377C180.46 48.0377 177.941 49.8856 177.941 52.5751C177.941 54.7186 179.55 55.9799 183.485 56.9455L186.849 57.7657C193.113 59.2369 195.715 61.7357 195.715 66.1705C195.715 71.8428 191.165 75.3955 183.929 75.3955C177.159 75.3955 172.588 71.9263 172.292 66.4423L176.969 66.4425Z"
        fill="white"
      />
      <path
        d="M205.576 47.5538V52.7851H209.808V56.3782H205.576V68.5646C205.576 70.4577 206.423 71.3399 208.284 71.3399C208.786 71.3312 209.288 71.2962 209.787 71.2349V74.8067C208.95 74.9619 208.1 75.0322 207.249 75.0165C202.743 75.0165 200.985 73.3355 200.985 69.0485V56.3782H197.749V52.7851H200.985V47.5538H205.576Z"
        fill="white"
      />
      <path
        d="M212.26 63.8389C212.26 56.8838 216.384 52.5133 222.816 52.5133C229.27 52.5133 233.375 56.8837 233.375 63.8389C233.375 70.8131 229.291 75.1644 222.816 75.1644C216.344 75.1644 212.26 70.813 212.26 63.8389ZM228.72 63.8389C228.72 59.0678 226.518 56.2519 222.816 56.2519C219.114 56.2519 216.915 59.0893 216.915 63.8389C216.915 68.629 219.114 71.4234 222.816 71.4234C226.518 71.4234 228.72 68.629 228.72 63.8389H228.72Z"
        fill="white"
      />
      <path
        d="M237.142 52.7851H241.5V56.5475H241.605C241.9 55.3724 242.593 54.334 243.568 53.6067C244.543 52.8794 245.741 52.5076 246.959 52.5538C247.486 52.5519 248.011 52.6087 248.525 52.7231V56.9672C247.86 56.7655 247.167 56.6728 246.472 56.693C245.808 56.6662 245.146 56.7825 244.532 57.0337C243.918 57.2849 243.366 57.6651 242.913 58.1483C242.461 58.6314 242.119 59.2061 241.912 59.8327C241.704 60.4594 241.636 61.1233 241.711 61.7787V74.8902H237.142L237.142 52.7851Z"
        fill="white"
      />
      <path
        d="M269.59 68.3976C268.975 72.4104 265.04 75.1643 260.006 75.1643C253.531 75.1643 249.511 70.8558 249.511 63.9437C249.511 57.01 253.552 52.5132 259.814 52.5132C265.972 52.5132 269.844 56.7144 269.844 63.4167V64.9713H254.124V65.2455C254.051 66.059 254.154 66.8786 254.424 67.6497C254.695 68.4209 255.128 69.1259 255.695 69.7181C256.261 70.3103 256.948 70.7761 257.709 71.0845C258.471 71.3928 259.289 71.5368 260.111 71.5067C261.191 71.6072 262.274 71.3589 263.201 70.7987C264.127 70.2385 264.846 69.3964 265.252 68.3976L269.59 68.3976ZM254.145 61.8001H265.273C265.314 61.0687 265.202 60.3367 264.945 59.6501C264.688 58.9635 264.29 58.3371 263.778 57.8103C263.265 57.2835 262.648 56.8676 261.966 56.5888C261.284 56.3099 260.551 56.1741 259.814 56.1898C259.07 56.1854 258.332 56.3274 257.644 56.6075C256.955 56.8877 256.329 57.3005 255.802 57.8221C255.275 58.3437 254.858 58.9637 254.573 59.6465C254.289 60.3293 254.143 61.0613 254.145 61.8002V61.8001Z"
        fill="white"
      />
      <path
        d="M93.6654 21.7494C94.6233 21.6812 95.5844 21.8249 96.4796 22.1702C97.3748 22.5155 98.1816 23.0538 98.8419 23.7463C99.5022 24.4388 99.9995 25.2682 100.298 26.1747C100.596 27.0812 100.689 28.0421 100.568 28.9883C100.568 33.6425 98.035 36.3178 93.6654 36.3178H88.3667V21.7494H93.6654ZM90.6451 34.2575H93.4109C94.0954 34.2982 94.7803 34.1867 95.4159 33.9313C96.0515 33.6759 96.6218 33.2829 97.0852 32.781C97.5486 32.2791 97.8935 31.6809 98.0948 31.0299C98.296 30.379 98.3486 29.6916 98.2486 29.018C98.3413 28.3469 98.2834 27.6639 98.0791 27.0177C97.8748 26.3715 97.529 25.7782 97.0667 25.2803C96.6043 24.7823 96.0368 24.3922 95.4048 24.1377C94.7727 23.8832 94.0919 23.7707 93.4109 23.8082H90.6451V34.2575Z"
        fill="white"
      />
      <path
        d="M103.142 30.8157C103.072 30.0932 103.155 29.3643 103.386 28.6756C103.617 27.9869 103.99 27.3537 104.481 26.8166C104.973 26.2795 105.572 25.8503 106.24 25.5567C106.909 25.263 107.631 25.1113 108.362 25.1113C109.093 25.1113 109.816 25.263 110.484 25.5567C111.153 25.8503 111.752 26.2795 112.243 26.8166C112.735 27.3537 113.108 27.9869 113.339 28.6756C113.569 29.3643 113.653 30.0932 113.583 30.8157C113.654 31.539 113.572 32.2689 113.342 32.9587C113.111 33.6485 112.739 34.2829 112.247 34.8211C111.755 35.3592 111.156 35.7893 110.487 36.0835C109.818 36.3778 109.094 36.5299 108.362 36.5299C107.631 36.5299 106.907 36.3778 106.238 36.0835C105.569 35.7893 104.969 35.3592 104.478 34.8211C103.986 34.2829 103.613 33.6485 103.383 32.9587C103.153 32.2689 103.071 31.539 103.142 30.8157V30.8157ZM111.336 30.8157C111.336 28.4326 110.258 27.039 108.366 27.039C106.467 27.039 105.399 28.4326 105.399 30.8157C105.399 33.218 106.467 34.6009 108.366 34.6009C110.258 34.6008 111.336 33.2084 111.336 30.8157H111.336Z"
        fill="white"
      />
      <path
        d="M127.462 36.3176H125.196L122.908 28.2204H122.735L120.457 36.3176H118.212L115.16 25.3235H117.376L119.36 33.7127H119.523L121.799 25.3235H123.895L126.171 33.7127H126.344L128.317 25.3235H130.502L127.462 36.3176Z"
        fill="white"
      />
      <path
        d="M133.068 25.3234H135.172V27.0699H135.335C135.612 26.4426 136.079 25.9168 136.671 25.5657C137.263 25.2146 137.951 25.0557 138.638 25.1112C139.177 25.0709 139.718 25.1516 140.221 25.3472C140.724 25.5427 141.176 25.8482 141.544 26.2409C141.912 26.6336 142.186 27.1035 142.347 27.6158C142.507 28.1281 142.549 28.6696 142.47 29.2003V36.3174H140.285V29.7451C140.285 27.9784 139.512 27.0997 137.897 27.0997C137.531 27.0828 137.166 27.1446 136.826 27.2809C136.487 27.4172 136.181 27.6248 135.93 27.8895C135.679 28.1541 135.489 28.4695 135.372 28.8141C135.255 29.1586 135.214 29.5242 135.253 29.8858V36.3175H133.068L133.068 25.3234Z"
        fill="white"
      />
      <path d="M145.951 21.0316H148.136V36.3176H145.951V21.0316Z" fill="white" />
      <path
        d="M151.173 30.8158C151.104 30.0933 151.187 29.3642 151.418 28.6755C151.649 27.9868 152.022 27.3536 152.513 26.8165C153.005 26.2794 153.604 25.8502 154.273 25.5566C154.941 25.2629 155.664 25.1112 156.395 25.1112C157.125 25.1112 157.848 25.2629 158.517 25.5566C159.185 25.8502 159.784 26.2794 160.276 26.8165C160.767 27.3536 161.141 27.9868 161.371 28.6755C161.602 29.3642 161.685 30.0933 161.616 30.8158C161.687 31.5391 161.604 32.2691 161.374 32.9589C161.144 33.6487 160.771 34.283 160.279 34.8211C159.788 35.3593 159.188 35.7893 158.519 36.0836C157.85 36.3779 157.126 36.5299 156.395 36.5299C155.663 36.5299 154.939 36.3779 154.27 36.0836C153.601 35.7893 153.002 35.3593 152.51 34.8211C152.018 34.283 151.645 33.6487 151.415 32.9589C151.185 32.2691 151.102 31.5391 151.173 30.8158V30.8158ZM159.367 30.8158C159.367 28.4327 158.289 27.039 156.398 27.039C154.499 27.039 153.43 28.4327 153.43 30.8158C153.43 33.2181 154.499 34.601 156.398 34.601C158.289 34.6009 159.367 33.2085 159.367 30.8158H159.367Z"
        fill="white"
      />
      <path
        d="M163.916 33.2084C163.916 31.2294 165.4 30.0885 168.033 29.9263L171.032 29.7547V28.8057C171.032 27.6445 170.259 26.9889 168.766 26.9889C167.546 26.9889 166.701 27.4335 166.459 28.2108H164.343C164.567 26.3224 166.355 25.1112 168.867 25.1112C171.642 25.1112 173.207 26.4834 173.207 28.8057V36.3176H171.104V34.7725H170.931C170.58 35.3267 170.088 35.7785 169.504 36.0818C168.919 36.3851 168.265 36.5289 167.606 36.4987C167.141 36.5467 166.672 36.4975 166.227 36.3541C165.783 36.2108 165.374 35.9766 165.026 35.6666C164.678 35.3566 164.4 34.9777 164.209 34.5543C164.018 34.1309 163.918 33.6724 163.916 33.2084V33.2084ZM171.032 32.2689V31.3498L168.329 31.5215C166.804 31.6228 166.113 32.1378 166.113 33.107C166.113 34.0965 166.977 34.6723 168.166 34.6723C168.514 34.7073 168.866 34.6724 169.2 34.5697C169.534 34.4669 169.844 34.2984 170.112 34.0741C170.379 33.8499 170.599 33.5745 170.757 33.2644C170.915 32.9543 171.009 32.6157 171.032 32.2689V32.2689Z"
        fill="white"
      />
      <path
        d="M176.079 30.8158C176.079 27.3418 177.877 25.141 180.674 25.141C181.366 25.1094 182.052 25.274 182.654 25.6155C183.255 25.9571 183.746 26.4615 184.069 27.0699H184.232V21.0316H186.417V36.3176H184.323V34.5806H184.15C183.802 35.1848 183.294 35.683 182.681 36.0211C182.069 36.3592 181.374 36.5244 180.674 36.4987C177.858 36.4989 176.079 34.2981 176.079 30.8158ZM178.336 30.8158C178.336 33.1476 179.442 34.5508 181.293 34.5508C183.135 34.5508 184.273 33.1274 184.273 30.8253C184.273 28.534 183.123 27.0902 181.293 27.0902C179.454 27.0902 178.335 28.5029 178.335 30.8158H178.336Z"
        fill="white"
      />
      <path
        d="M195.456 30.8157C195.386 30.0932 195.47 29.3643 195.7 28.6756C195.931 27.9869 196.304 27.3537 196.796 26.8166C197.287 26.2795 197.886 25.8503 198.555 25.5567C199.223 25.263 199.946 25.1113 200.677 25.1113C201.408 25.1113 202.13 25.263 202.799 25.5567C203.467 25.8503 204.066 26.2795 204.558 26.8166C205.049 27.3537 205.422 27.9869 205.653 28.6756C205.884 29.3643 205.967 30.0932 205.897 30.8157C205.968 31.539 205.886 32.2689 205.656 32.9587C205.426 33.6485 205.053 34.2829 204.561 34.8211C204.07 35.3592 203.47 35.7893 202.801 36.0835C202.132 36.3778 201.408 36.5299 200.677 36.5299C199.945 36.5299 199.221 36.3778 198.552 36.0835C197.883 35.7893 197.284 35.3592 196.792 34.8211C196.3 34.2829 195.928 33.6485 195.697 32.9587C195.467 32.2689 195.385 31.539 195.456 30.8157V30.8157ZM203.65 30.8157C203.65 28.4326 202.572 27.039 200.68 27.039C198.781 27.039 197.713 28.4326 197.713 30.8157C197.713 33.218 198.781 34.6009 200.68 34.6009C202.572 34.6008 203.65 33.2084 203.65 30.8157Z"
        fill="white"
      />
      <path
        d="M208.829 25.3234H210.932V27.0699H211.095C211.372 26.4426 211.839 25.9168 212.432 25.5657C213.024 25.2146 213.711 25.0557 214.399 25.1112C214.938 25.0709 215.478 25.1516 215.981 25.3472C216.484 25.5427 216.937 25.8482 217.305 26.2409C217.673 26.6336 217.947 27.1035 218.107 27.6158C218.268 28.1281 218.31 28.6696 218.231 29.2003V36.3174H216.046V29.7451C216.046 27.9784 215.273 27.0997 213.657 27.0997C213.291 27.0828 212.926 27.1446 212.587 27.2809C212.247 27.4172 211.942 27.6248 211.691 27.8895C211.44 28.1541 211.249 28.4695 211.132 28.8141C211.015 29.1586 210.975 29.5242 211.014 29.8858V36.3175H208.829V25.3234Z"
        fill="white"
      />
      <path
        d="M230.576 22.5862V25.3735H232.974V27.2011H230.576V32.8543C230.576 34.0059 231.054 34.5102 232.141 34.5102C232.42 34.5093 232.698 34.4926 232.974 34.4601V36.2675C232.582 36.3372 232.185 36.3743 231.786 36.3783C229.356 36.3783 228.389 35.5295 228.389 33.4098V27.201H226.631V25.3734H228.389V22.5862H230.576Z"
        fill="white"
      />
      <path
        d="M235.959 21.0316H238.124V27.0902H238.297C238.588 26.4571 239.068 25.9282 239.672 25.5758C240.276 25.2234 240.974 25.0645 241.673 25.1208C242.209 25.0918 242.744 25.1806 243.241 25.381C243.739 25.5813 244.185 25.8881 244.55 26.2796C244.914 26.671 245.186 27.1375 245.348 27.6457C245.51 28.1539 245.557 28.6912 245.485 29.2194V36.3176H243.298V29.7547C243.298 27.9986 242.475 27.1093 240.931 27.1093C240.556 27.0787 240.178 27.1299 239.824 27.2595C239.471 27.389 239.15 27.5937 238.885 27.8592C238.619 28.1247 238.415 28.4445 238.287 28.7965C238.159 29.1484 238.11 29.5238 238.144 29.8965V36.3175H235.959L235.959 21.0316Z"
        fill="white"
      />
      <path
        d="M258.224 33.3491C257.928 34.3539 257.284 35.2231 256.407 35.8045C255.531 36.386 254.476 36.6427 253.427 36.5298C252.698 36.5489 251.973 36.4102 251.303 36.1232C250.633 35.8363 250.034 35.408 249.548 34.8681C249.061 34.3282 248.699 33.6896 248.486 32.9966C248.273 32.3036 248.214 31.5729 248.314 30.8551C248.216 30.1351 248.276 29.4028 248.489 28.7078C248.701 28.0128 249.062 27.3712 249.546 26.8266C250.03 26.2819 250.626 25.8469 251.294 25.551C251.962 25.255 252.686 25.1051 253.418 25.1113C256.498 25.1113 258.356 27.2012 258.356 30.6536V31.4107H250.539V31.5323C250.505 31.9357 250.556 32.3419 250.689 32.7246C250.822 33.1073 251.034 33.4582 251.312 33.7547C251.589 34.0512 251.926 34.2869 252.301 34.4466C252.676 34.6062 253.08 34.6864 253.487 34.6819C254.01 34.7442 254.539 34.6507 255.008 34.4134C255.477 34.1761 255.865 33.8056 256.121 33.3491L258.224 33.3491ZM250.539 29.806H256.131C256.158 29.437 256.108 29.0664 255.982 28.7179C255.857 28.3695 255.66 28.0509 255.403 27.7827C255.147 27.5145 254.836 27.3025 254.492 27.1605C254.148 27.0185 253.778 26.9495 253.406 26.9579C253.028 26.9532 252.653 27.0237 252.303 27.1652C251.953 27.3066 251.635 27.5163 251.368 27.7817C251.101 28.0471 250.89 28.363 250.747 28.7106C250.605 29.0583 250.534 29.4308 250.539 29.806H250.539Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3143_680657">
        <rect width="294.192" height="97.6622" fill="white" transform="translate(0.670166 0.432251)" />
      </clipPath>
    </defs>
  </svg>
)

export default DownloadAppstoreIcon
